<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        elevation="0"
        v-bind="attrs"
        color="primary"
        class="mx-4 mb-4 no-text-transform"
        :class="{'btn-multiline': $vuetify.breakpoint.smAndDown}"
        :height="$vuetify.breakpoint.smAndDown ? 60 : 35"
        :disabled="!isLastRegenerationFinished"
        v-on="on"
      >
        <span
          v-if="!isLastRegenerationFinished"
          class="text-wrap"
        >{{ $t('buttons.regenerate.info') }}</span>
        <span
          v-else
          class="text-wrap"
        >{{ $t('buttons.regenerate.generate') }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('regenerate.title') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pa-6">
        <p class="font-weight-bold">
          {{ $t('regenerate.subtitle') }}
        </p>
        <Feature :feature-slug="featureNames.BILLWERK">
          <template>
            <div>
              <p>{{ $t('regenerate.body-texts.0') }}:</p>
              <div class="pb-3 text-center font-weight-bold">
                {{ $t('regenerate.body-texts.1') }}:
                <span class="pl-3 text-center font-weight-bold text-h6">
                  {{ regenerationConditions.included ? 0 : regenerationConditions.price.value }} {{ regenerationConditions.price.currency }}
                </span>
              </div>
              <p v-if="company.productType === 'EXPERT' && regenerationConditions.included">
                <strong>{{ $t('labels.caution') }}:</strong><br>
                {{ $t('regenerate.regeneration-included-text') }}
                {{ regenerationConditions.price.value }} {{ regenerationConditions.price.currency }}.
              </p>
              <p v-if="company.productType === 'EXPERT' && !regenerationConditions.included">
                <strong>{{ $t('labels.caution') }}:</strong><br>
                {{ $t('regenerate.regeneration-not-included-text', { value: regenerationConditions.price.value, currency: regenerationConditions.price.currency }) }}
              </p>
              <p v-if="company.productType === 'BASIC'">
                <strong>{{ $t('labels.caution') }}:</strong><br>
                {{ $t('regenerate.basic-text-price', { value: regenerationConditions.price.value, currency: regenerationConditions.price.currency }) }}
              </p>
              <p>{{ $t('regenerate.basic-text-hint') }}</p>
              <p>{{ $t('regenerate.basic-text-info') }}</p>
            </div>
          </template>
          <template v-slot:inactive>
            <p
              v-if="hasRegenerationLimit"
              v-html="$t('regenerate.generation-limit', { max: maxGenerationsPerYear })"
            />
          </template>
        </Feature>
        <p
          v-html="$t('regenerate.generation-time', { email: $auth.user.email })"
        />
        <p>
          {{ $t('regenerate.generation-info') }}
        </p>
        <p>
          {{ $t('regenerate.generation-legal', { legalCompanyName }) }}
        </p>
        <span v-if="company.productType !== 'BASIC'">
          {{ $t('regenerate.basic-support') }}:
        </span>
        <client-advisor
          class="mt-4 ma-lg-6"
          :company="company"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <div class="flex-column flex-lg-row">
          <v-btn
            text
            @click="dialog = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="submit"
          >
            <Feature :feature-slug="featureNames.BILLWERK">
              <template>
                <div>
                  {{ $t('buttons.regenerate.cta') }}
                </div>
              </template>
              <template v-slot:inactive>
                <div>
                  {{ $t('buttons.regenerate.generate') }}
                </div>
              </template>
            </Feature>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import START_REGENERATION from '../queries/StartRegeneration.gql'
import REGENERATION_CONDITIONS from '../queries/RegenerationConditions.gql'
import IS_LAST_REGENERATION_FINISHED from '../queries/IsLastRegenerationFinished.gql'
import ClientAdvisor from '@/components/ClientAdvisor.vue'
import bus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    ClientAdvisor
  },
  mixins: [brandingMixin, featureMixin],
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isLoading: false,
      regenerationConditions: {
        price: {}
      }
    }
  },
  computed: {
    hasRegenerationLimit () {
      return this.$features.feature(this.featureNames.REGENERATION)?.config?.hasLimit
    },
    maxGenerationsPerYear () {
      return this.$features.feature(this.featureNames.REGENERATION)?.config?.maxGenerationsPerYear
    },
    isGenerationDisabled () {
      return !this.isFeatureActive(this.featureNames.BILLWERK) && !this.regenerationConditions.included
    }
  },
  apollo: {
    regenerationConditions: {
      query: REGENERATION_CONDITIONS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    isLastRegenerationFinished: {
      query: IS_LAST_REGENERATION_FINISHED,
      variables () {
        return { companyId: this.company.id }
      },
      skip () {
        return !this.company
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      if (this.isGenerationDisabled) {
        bus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'error',
          text: this.$t('alerts.regenerate.error-too-many')
        })
        this.isLoading = false
        return
      }

      try {
        await this.$apollo.mutate({
          mutation: START_REGENERATION,
          variables: {
            companyId: this.company.id
          },
          refetchQueries: [{
            query: IS_LAST_REGENERATION_FINISHED,
            variables: { companyId: this.company.id }
          }]
        })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.regenerate.error') })
      }
      this.isLoading = false
      this.dialog = false
    }
  }
}
</script>
