<template>
  <div>
    <Step
      :action="() => dialog = true"
      :title="$t('settings.delete-account.step-free.title')"
      icon="mdi-delete"
      type="error"
    >
      <div class="black--text">
        {{ $t('settings.delete-account.step-free.body-texts.0', { readablePlatformName }) }}<br>

        {{ $t('settings.delete-account.step-free.body-texts.1') }}<br><br>
        {{ $t('settings.delete-account.step-free.body-texts.2', { date: formatDate(date) }) }}:
        <ul>
          <li>{{ $t('settings.delete-account.step-free.list.0') }}</li>
          <li>{{ $t('settings.delete-account.step-free.list.1') }}</li>
          <li>{{ $t('settings.delete-account.step-free.list.2', { readablePlatformName }) }}</li>
          <li>{{ $t('settings.delete-account.step-free.list.3') }}</li>
          <li>{{ $t('settings.delete-account.step-free.list.4') }}</li>
        </ul>
      </div>
    </Step>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('settings.delete-account.step-free.body-texts.0', { readablePlatformName }) }}
        </v-card-title>
        <v-card-text>
          {{ $t('settings.delete-account.step-free.body-texts.3') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            :loading="loading"
            :disabled="loading"
            color="error"
            @click="deleteAccount"
          >
            {{ $t('buttons.delete-account.delete-completely') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'

import COMPANY from '@/modules/company/queries/Company.gql'
import DELETE_COMPANY_WITH_DELAY from '../../queries/DeleteCompanyWithDelay.gql'

import Step from './Step.vue'

export default {
  components: { Step },
  mixins: [brandingMixin],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    date () {
      const date = new Date()
      date.setDate(date.getDate() + 7)
      return date
    }
  },
  methods: {
    async deleteAccount () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_COMPANY_WITH_DELAY,
          variables: { id: this.$auth.user.companyId },
          refetchQueries: [{ query: COMPANY, variables: { id: this.$auth.user.companyId } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.delete-account.success-completely') })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-account.error-completely') })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
