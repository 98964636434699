<template>
  <Feature
    v-slot="{feature}"
    :feature-slug="featureNames.COMPANY"
  >
    <div class="mid-gray">
      <Feature :feature-slug="featureNames.ONBOARDING_PREVIEW">
        <div>
          <div v-if="isOnboarding && data.previewLink">
            <v-alert
              class="mx-n4"
              type="info"
              text
            >
              Sie möchten wissen, an welchen Stellen Ihre Daten und Bilder erscheinen?
              <a
                :href="data.previewLink"
                target="_blank"
                rel="noopener noreferrer"
              >Beispiel-Werbemittel ansehen</a>
            </v-alert>
          </div>
        </div>
      </Feature>
      <div>
        <h2 class="pr-4 ml-n2">
          Kontaktdaten
        </h2>
      </div>

      <div class="pb-4 mb-2">
        <h4 class="pr-4 ml-n2">
          Wer soll in den Ratgebern und Checklisten sowie auf den Landingpages als Autor / Ansprechpartner erscheinen?
        </h4>
      </div>
      <v-row
        class="d-flex justify-space-between"
        :class="{'flex-column': $vuetify.breakpoint.smAndDown}"
      >
        <v-col
          cols="12"
          md="2"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            ref="ambassador.salutation"
            v-model.lazy="data.ambassador.salutation"
            outlined
            label="Anrede"
            placeholder="Herr"
            validate-on-blur
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            id="ambassador_firstname"
            ref="ambassador.firstname"
            v-model.lazy="data.ambassador.firstname"
            outlined
            label="Vorname"
            placeholder="Max"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="pa-0"
        >
          <v-text-field
            ref="ambassador.lastname"
            v-model.lazy="data.ambassador.lastname"
            outlined
            label="Nachname"
            placeholder="Makler"
            validate-on-blur
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-text-field
          ref="ambassador.role"
          v-model.lazy="data.ambassador.role"
          outlined
          label="Funktion / Rolle"
          placeholder="Geschäftsführer"
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-row>
      <div class="pb-4 mb-2">
        <h4 class="pr-4 ml-n2">
          Welche E-Mail-Adresse und Telefonnummer soll in den Kontaktdaten stehen?
          Diese Adresse ist auch der Absender der E-Mail-Serien.
        </h4>
      </div>
      <v-row class="d-flex justify-space-between">
        <v-col
          cols="12"
          sm="6"
          class="pr-0 pa-0 pr-md-2"
        >
          <PhoneField
            id="ambassador_phone"
            v-model="data.ambassador.phone"
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
            validate-on-blur
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="pa-0"
        >
          <PhoneField
            id="ambassador_mobile"
            v-model="data.ambassador.mobile"
            label="Mobilnummer"
            :rules="[rules.optionalForOnboarding(isOnboarding)]"
            validate-on-blur
          />
        </v-col>
      </v-row>

      <v-row class="d-flex justify-space-between py-4">
        <v-text-field
          ref="ambassador.email"
          v-model.lazy="data.ambassador.email"
          type="email"
          outlined
          label="E-Mail"
          placeholder="info@max-makler.de"
          :rules="[rules.email, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
          :disabled="!isOnboarding"
        />
      </v-row>
      <div class="pb-4 mb-2">
        <h2 class="pr-4 ml-n2">
          Das Motto Ihres Unternehmens
        </h2>
      </div>
      <v-row>
        <v-text-field
          ref="slogan"
          v-model.lazy="data.slogan"
          outlined
          label="Slogan"
          placeholder="Immobilienverkauf? - Mit Ihren Experten aus Musterstadt ganz einfach."
          validate-on-blur
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-row>

      <div v-if="feature.config.isColorEditable">
        <div class="pb-4">
          <h2 class="pr-4 d-inline">
            Ihre Farben
          </h2>
        </div>
        <div class="pb-4">
          <h4 class="pb-4 d-inline">
            Bitte achten Sie bei der Farbwahl auf genügend Kontrast zu weiss.
          </h4>
        </div>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="pr-2 pa-0 align-center d-flex flex-column"
          >
            <div class="px-4">
              <h4>
                Was ist Ihre primäre Farbe?
              </h4>
              <ColorPicker v-model.lazy="data.theme.colors.primary" />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pr-2 pa-0 align-center d-flex flex-column"
          >
            <div class="px-4">
              <h4>
                Was ist Ihre sekundäre Farbe?
              </h4>
              <ColorPicker v-model.lazy="data.theme.colors.secondary" />
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pt-4 pb-4">
        <h2 class="pr-4 ml-n2">
          Bilder
        </h2>
      </div>
      <v-row>
        <v-col
          v-for="image of imageArray"
          :key="image.name"
          cols="12"
          md="6"
        >
          <ImageContainer
            v-bind="image"
            :save-image="saveImage"
            :delete-image="deleteImage"
          />
        </v-col>
      </v-row>

      <Feature :feature-slug="featureNames.CONTRACT">
        <div>
          <div
            v-if="isOnboarding"
            class="pt-4"
          >
            <h2 class="pr-4 d-inline">
              Logo-Freigabe
            </h2>
          </div>
          <v-row v-if="isOnboarding">
            <v-col>
              <h4 class="pb-4">
                Sie sind ein wertvoller Kunde und wir würden uns freuen, Sie als unseren Referenz-Kunden zu präsentieren. Dürfen wir Ihr Logo auf unserer Webseite platzieren?
              </h4>
              <v-radio-group
                v-model="data.isReferenceCustomer"
                row
                :rules="[rules.optionalBooleanForOnboarding(isOnboarding)]"
              >
                <v-radio
                  label="Ja, gerne dürfen Sie uns als Referenz-Kunden aufnehmen."
                  :value="true"
                />
                <v-radio
                  label="Nein, ich möchte nicht auf Ihrer Webseite sichtbar sein."
                  :value="false"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
      </Feature>

      <template>
        <div class="pt-4">
          <h2 class="pr-4 d-inline">
            Signatur
          </h2>
        </div>
        <h4 class="pb-4 pr-4 d-inline">
          {{ signatureDescription }}
        </h4>

        <v-switch
          v-if="defaultSignatureAvailable"
          v-model="data.ambassador.signature.customTemplateSignature"
          label="Ich möchte lieber eine eigene Signatur aufsetzen und nutzen."
        />
        <v-row
          class="mt-2"
        >
          <v-col
            cols="12"
            md="6"
          >
            <div class="signature__wrapper">
              <v-textarea
                v-model.lazy="data.ambassador.signature.text"
                outlined
                label="Signatur"
                :rules="data.ambassador.signature.customTemplateSignature ? [rules.optionalForOnboarding(isOnboarding)]: []"
                :disabled="!data.ambassador.signature.customTemplateSignature"
              />
              <v-dialog
                v-model="previewTextSignature"
                max-width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="preview__icon"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-eye
                    </v-icon> <span>Vorschau Text Signatur</span>
                  </span>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h6">Text Vorschau</span>
                  </v-card-title>
                  <v-card-text>
                    <signature-preview
                      :company="data"
                      type="text"
                      :custom-signature="data.ambassador.signature.customTemplateSignature ? data.ambassador.signature.text : ''"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div class="signature__wrapper">
              <v-textarea
                v-model.lazy="data.ambassador.signature.html"
                outlined
                label="HTML Signatur (optional)"
                :disabled="!data.ambassador.signature.customTemplateSignature"
              />
              <v-dialog
                v-model="previewHTMLSignature"
                max-width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="preview__icon"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-eye
                    </v-icon> <span>Vorschau HTML Signatur</span>
                  </span>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h6">HTML Vorschau</span>
                  </v-card-title>
                  <v-card-text>
                    <signature-preview
                      :company="data"
                      type="html"
                      :custom-signature="data.ambassador.signature.customTemplateSignature ? data.ambassador.signature.html : ''"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>
  </Feature>
</template>

<script>
import { email, optionalForOnboarding, optionalBooleanForOnboarding } from '@/lib/validation'
import featureMixin from '@/mixins/feature'
import ColorPicker from '../ColorPicker.vue'
import PhoneField from '@/components/PhoneField.vue'
import ImageContainer from '@/components/ImageContainer.vue'
import heroImage from '@/lib/customHeroMapper'
import SignaturePreview from '@/components/SignaturePreview.vue'

export default {
  components: { ColorPicker, PhoneField, SignaturePreview, ImageContainer },
  mixins: [featureMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isOnboarding: {
      type: Boolean,
      default: false
    },
    saveAmbassadorImage: {
      type: Function,
      required: true
    },
    saveCompanyImage: {
      type: Function,
      required: true
    },
    deleteCompanyImage: {
      type: Function,
      default: () => {}
    },
    variant: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      images: {},
      ambassadorImages: {},
      defaultSignatures: {
        text: 'default text',
        html: '<h3>default html</h3>'
      },
      previewHTMLSignature: false,
      previewTextSignature: false
    }
  },
  computed: {
    hasLpValuationHero () {
      return this.getFeature(this.featureNames.VALUATION).config.hasLpValuationHero
    },
    hasSetValuationHeroImage () {
      return this.images.lpValuationHero || !!this.data?.images?.lpValuationHero?.url
    },
    valuationHeroImage () {
      return this.data?.images?.lpValuationHero?.url || heroImage({ variant: this.variant, lpSlug: 'immobilienbewertung', useDefault: !this.hasLpValuationHero })
    },
    defaultSignatureAvailable () {
      return this.data.mailProvider === 'HOPPERMATION'
    },
    signatureDescription () {
      if (this.data.mailProvider === 'HOPPERMATION') {
        return 'Die Mails an Ihre Kontakte werden mit einer Standardsignatur versendet. Diese setzt sich aus den angegebenen Kontaktdaten zusammen. Alternativ haben Sie hier die Möglichkeit eine eigene Signatur anzugeben.'
      }
      return 'Diese E-Mail Signatur wird in den automatisch erzeugten E-Mails verwendet, die an Ihre Leads versendet werden.'
    },
    imageArray () {
      return [
        {
          name: 'logo',
          title: 'Logo',
          src: this.images.logo || this.data.images.logo.url,
          disabled: !this.getFeature(this.featureNames.COMPANY)?.config?.isLogoEditable,
          minHeight: 100,
          minWidth: 1200
        },
        {
          name: 'team',
          title: 'Teambild',
          src: this.images.team || this.data.images.team.url,
          minHeight: 800,
          minWidth: 1200,
          ratio: 1.5
        },
        {
          name: 'image',
          title: 'Portrait',
          src: this.ambassadorImages.image || this.data.ambassador.image?.url,
          minHeight: 1200,
          minWidth: 1200,
          ratio: 1
        },
        {
          name: 'region',
          title: 'Regionenbild(optional)',
          src: this.images.region || this.data.images.region.url,
          minHeight: 800,
          minWidth: 1200,
          ratio: 1.5,
          disabled: this.isOnboarding
        },
        {
          name: 'lpValuationHero',
          title: 'Hintergrundbild Immobilienbewertung',
          src: this.images.lpValuationHero || this.valuationHeroImage,
          minHeight: 650,
          minWidth: 1950,
          ratio: 3,
          disabled: !this.hasLpValuationHero || this.isOnboarding,
          askForImageRights: true,
          canDeleteImage: true
        }
      ].filter(({ disabled }) => !disabled)
    }
  },
  created () {
    this.rules = { email, optionalForOnboarding, optionalBooleanForOnboarding }
  },
  methods: {
    saveImage (image, type) {
      if (type === 'image') {
        this.saveImageAmbassador(image, type)
      } else {
        this.$set(this.images, type, URL.createObjectURL(image))
        this.saveCompanyImage(image, type)
      }
    },
    deleteImage (type) {
      this.$set(this.images, type, null)
      this.deleteCompanyImage(type)
    },
    saveImageAmbassador (image, type) {
      this.$set(this.ambassadorImages, type, URL.createObjectURL(image))
      this.saveAmbassadorImage(image, type)
    }
  }
}
</script>
<style>
.signature-preview-wrapper {
  padding: 16px 0;
  margin: 0 16px;
}
.signature__wrapper {
  position: relative;
}
.preview__icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
}
.preview__iframe {
  border: none;
}
</style>
