<template>
  <SettingsCard
    :title="$t('settings.language.title')"
    :subtitle="$t('settings.language.subtitle', { readablePlatformName })"
    :is-default-open="false"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ $t('settings.language.body-texts.0') }}<br>
            </span>
            <span class="grey--text">
              {{ $t('settings.language.body-texts.1') }}
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <LanguageSwitch />
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import branding from '@/mixins/branding'

export default {
  components: { SettingsCard, LanguageSwitch },
  mixins: [branding]
}
</script>
