<template>
  <SettingsCard
    :title="$t('settings.company.additional.title')"
    icon="mdi-wrench-outline"
    :is-default-open="false"
  >
    <div class="px-4 pt-4">
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.COMPANY"
      >
        <v-row v-if="feature.config.isSloganEditable">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.company.additional.body-texts.0') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.company.additional.body-texts.1') }}<br>
                {{ $t('settings.company.additional.body-texts.2') }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="justify-center px-0 mt-4 d-flex px-md-2"
          >
            <TextSettings
              :fields="sloganField"
              @update="update"
            />
          </v-col>
        </v-row>
      </Feature>

      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.COMPANY"
      >
        <v-row v-if="feature.config.isProvisionEditable">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.company.additional.body-texts.3') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.company.additional.body-texts.4') }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="justify-center px-0 mt-4 d-flex px-md-2"
          >
            <TextSettings
              :fields="commissionField"
              @update="update"
            />
          </v-col>
        </v-row>
      </Feature>

      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.COMPANY"
      >
        <v-row v-if="feature.config.isRegionEditable">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  {{ $t('settings.company.additional.region.title') }}
                </span>
              </div>
              <span class="grey--text">
                {{ $t('settings.company.additional.region.subtitle') }}
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="justify-center px-0 mt-4 d-flex px-md-2"
          >
            <TextSettings
              :fields="regionField"
              @update="update"
            />
          </v-col>
        </v-row>
      </Feature>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '@/components/SettingsCard.vue'
import UPDATE_COMPANY_SLOGAN from './queries/UpdateCompanySlogan.gql'
import UPDATE_COMPANY_REGION from './queries/UpdateCompanyRegion.gql'
import UPDATE_COMPANY_COMMISSION from './queries/UpdateCompanyCommission.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import feature from '@/mixins/feature'

import TextSettings from './TextSettings'

import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    SettingsCard,
    TextSettings
  },
  mixins: [feature],
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      slugError: null,
      slugSuccess: null
    }
  },
  computed: {
    sloganField () {
      return [{
        label: this.$t('settings.company.additional.slogan-field.label'),
        name: 'slogan',
        placeholder: this.$t('settings.company.additional.slogan-field.placeholder'),
        required: false,
        value: this.company.slogan
      }]
    },
    commissionField () {
      return [{
        label: this.$t('settings.company.additional.commission-field.label'),
        name: 'commission',
        placeholder: this.$t('settings.company.additional.commission-field.placeholder'),
        required: false,
        value: this.company.commission,
        type: 'number',
        suffix: '%'
      }]
    },
    regionField () {
      return [{
        label: this.$t('settings.company.additional.region-field.label'),
        name: 'regionName',
        placeholder: this.$t('settings.company.additional.region-field.placeholder'),
        required: false,
        value: this.company.regionName,
        type: 'text'
      }]
    }
  },
  methods: {
    async update (values) {
      const mutations = {
        slogan: UPDATE_COMPANY_SLOGAN,
        commission: UPDATE_COMPANY_COMMISSION,
        regionName: UPDATE_COMPANY_REGION
      }

      const fieldName = Object.keys(values)[0]
      if (!mutations[fieldName]) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
        return
      }

      try {
        await this.$apollo.mutate({
          mutation: mutations[fieldName],
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              ...values
            }
          },
          refetchQueries: [{
            query: PENDING_CHANGES_FOR_REGENERATION,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.settings.company.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.settings.company.error') })
      }
    }
  }
}
</script>
