<template>
  <v-form
    ref="stepContainerForm"
    v-model="isValid"
    @submit.prevent="submit"
  >
    <slot />
    <div
      class="d-flex mt-4"
      :class="{'flex-column': hasSteps && $vuetify.breakpoint.smAndDown, 'justify-space-between': $vuetify.breakpoint.mdAndUp}"
    >
      <div
        v-if="!hideSecondaryActions"
        class="d-flex"
      >
        <v-tooltip
          v-if="hasSteps && !isDisabled"
          bottom
          :disabled="!isDirty"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                :disabled="isDirty"
                class="my-4"
                @click="$emit('nextStep', -1)"
              >
                zurück
              </v-btn>
            </div>
          </template>
          <span>
            Bitte Speichern Sie zuerst Ihre Änderungen
          </span>
        </v-tooltip>
        <v-btn
          v-if="!isDisabled"
          class="my-4 ml-6"
          @click="$router.go()"
        >
          Abbrechen
        </v-btn>
      </div>
      <v-spacer v-else />
      <div class="d-flex">
        <loading-button
          v-if="!isDisabled"
          color="primary"
          type="submit"
          :disabled="!isValid || !isDirty"
          :loading="isLoading"
          class="ma-4"
        >
          Speichern
        </loading-button>
        <v-tooltip
          v-if="hasSteps && !isDisabled"
          bottom
          :disabled="!isDirty"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                color="primary"
                :disabled="isDirty"
                class="my-4"
                @click="$emit('nextStep', 1)"
              >
                weiter
              </v-btn>
            </div>
          </template>
          <span>
            Bitte Speichern Sie zuerst Ihre Änderungen
          </span>
        </v-tooltip>
      </div>
    </div>
  </v-form>
</template>

<script>
import * as Sentry from '@sentry/vue'
import LoadingButton from '@/components/loading-button.vue'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { LoadingButton },
  props: {
    data: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    requiredData: {
      type: Function,
      required: true
    },
    hasChanges: {
      type: Function,
      default: (oldData, data) => JSON.stringify(oldData) !== JSON.stringify(data)
    },
    hasSteps: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hideSecondaryActions: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isComplete: false,
      isDirty: false,
      isValid: true,
      isLoading: false,
      missingData: [],
      oldData: JSON.parse(JSON.stringify(this.data))
    }
  },
  watch: {
    isDirty (value) {
      this.$emit('dirty', value)
    },
    missingData (value) {
      this.$emit('missingData', value)
    },
    data: {
      deep: true,
      handler (data) {
        if (this.hasChanges(this.oldData, data)) {
          this.isDirty = true
          this.missingData = this.getMissingKeys(data)
          this.oldData = JSON.parse(JSON.stringify(data))
        }
      }
    }
  },
  created () {
    this.missingData = this.getMissingKeys(this.data)
  },
  mounted () {
    this.$refs.stepContainerForm.validate()
  },
  methods: {
    getMissingKeys (object) {
      return Object.entries(this.requiredData(object)).filter(([key, value]) => !value).map(([key]) => key)
    },
    async submit () {
      this.isLoading = true

      if (this.$refs.stepContainerForm.validate()) {
        try {
          await this.onSubmit(this.data)
          this.isDirty = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Gespeichert' })
        } catch (error) {
          Sentry.addBreadcrumb({
            category: 'onboarding',
            message: 'Save failed',
            data: {
              data: JSON.stringify(this.data),
              error
            }
          })
          Sentry.captureException(error)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Fehler beim Speichern' })
        } finally {
          this.isLoading = false
        }
      }
      this.isLoading = false
    }
  }
}
</script>
