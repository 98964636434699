<template>
  <v-container class="pa-0 layout-container">
    <PaymentMethod />
    <v-divider class="my-4" />
    <BillingData :billing-self-service-portal-details="billingSelfServicePortalDetails" />
    <v-divider class="my-4" />
    <Invoice :billing-self-service-portal-details="billingSelfServicePortalDetails" />
  </v-container>
</template>

<script>
import PaymentMethod from './PaymentMethod'
import BillingData from './BillingData'
import Invoice from './Invoice'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'

export default {
  components: {
    PaymentMethod,
    BillingData,
    Invoice
  },

  apollo: {
    billingSelfServicePortalDetails: {
      query: SELF_SERVICE_PORTAL_URL
    }
  }

}
</script>
