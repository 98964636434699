<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t(toolbarTitle) }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-5">
        <component
          :is="component"
          :next-possible-termination-date="nextPossibleTerminationDate"
          :selected-variant="selectedVariant"
          @handleDowngrade="handleDowngrade"
          @handleCancelation="handleCancelation"
          @updateCancelationReasons="handleCancelationReason"
          @handleSwitchplan="handleSwitchplan"
        />
      </v-card-text>

      <v-card-actions :class="{'flex-column align-end ':$vuetify.breakpoint.xs}">
        <v-spacer />
        <v-btn
          v-if="state !== 'LOADING'"
          class="grey--text"
          text
          @click="close"
        >
          {{ $t('labels.close') }}
        </v-btn>
        <v-btn
          v-if="state !== 'LOADING'"
          text
          color="primary"
          class="ml-2"
          @click="nextState"
        >
          {{ $t(buttonText) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Feedback from './Feedback'
import Help from './Help'
import Downgrade from './Downgrade.vue'
import Information from './Information'
import Switchplan from './Switchplan.vue'
import Loading from './Loading'
import trackingEvents from '@/lib/trackingEvents'
import { isLightUser } from '@/lib/productTypes'
import UPDATE_CANCELATION from '../../queries/CreateOrUpdateCancelation.gql'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    Feedback,
    Downgrade,
    Help,
    Information,
    Switchplan,
    Loading
  },
  mixins: [featureMixin],
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    nextPossibleTerminationDate: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      state: 'FEEDBACK',
      cancelationReason: {
        reasons: [],
        other: ''
      },
      selectedVariant: isLightUser(this.$auth.user) ? 'delete' : 'free'
    }
  },
  computed: {
    isLight () {
      return isLightUser(this.$auth.user)
    },
    toolbarTitle () {
      return {
        FEEDBACK: 'settings.delete-account.toolbar-titles.0',
        DOWNGRADE: 'settings.delete-account.toolbar-titles.0',
        HELP: 'settings.delete-account.toolbar-titles.0',
        INFORMATION: 'settings.delete-account.toolbar-titles.0',
        SWITCHPLAN: 'settings.delete-account.toolbar-titles.1',
        LOADING: {
          free: 'settings.delete-account.toolbar-titles.2',
          delete: 'settings.delete-account.toolbar-titles.0'
        }[this.selectedVariant]
      }[this.state]
    },
    buttonText () {
      return {
        FEEDBACK: 'settings.delete-account.button-texts.0',
        DOWNGRADE: 'settings.delete-account.button-texts.0',
        HELP: 'settings.delete-account.button-texts.1',
        INFORMATION: 'settings.delete-account.button-texts.2',
        SWITCHPLAN: {
          free: 'settings.delete-account.button-texts.3',
          delete: 'settings.delete-account.button-texts.4'
        }[this.selectedVariant]
      }[this.state]
    },
    component () {
      return {
        FEEDBACK: () => Feedback,
        DOWNGRADE: () => Downgrade,
        HELP: () => Help,
        INFORMATION: () => Information,
        SWITCHPLAN: () => Switchplan,
        LOADING: () => Loading
      }[this.state]()
    }
  },
  methods: {
    nextState () {
      const event = {
        FEEDBACK: trackingEvents.CANCELATION_FEEDBACK_SEND,
        DOWNGRADE: trackingEvents.CANCELATION_DOWNGRADE_SKIPPED,
        HELP: trackingEvents.CANCELATION_HELP_SKIPPED,
        INFORMATION: trackingEvents.CANCELATION_INFORMATION_SKIPPED,
        SWITCHPLAN: trackingEvents.CANCELATION_SWITCHPLAN_SKIPPED
      }[this.state]

      /* eslint-disable-next-line no-unused-expressions */
      this.$gtm?.trackEvent({ event })

      return {
        FEEDBACK: () => this.submitCancelationReason(),
        DOWNGRADE: () => { this.state = 'HELP' },
        HELP: () => { this.state = 'INFORMATION' },
        INFORMATION: () => { this.isLight ? this.state = 'LOADING' : this.state = 'SWITCHPLAN' },
        SWITCHPLAN: () => { this.state = 'LOADING' }
      }[this.state]()
    },
    handleCancelationReason ({ reasons, other }) {
      this.cancelationReason = { reasons, other }
    },
    handleCancelation () {
      if (this.selectedVariant === 'free') {
        /* eslint-disable-next-line no-unused-expressions */
        this.$gtm?.trackEvent({ event: trackingEvents.CANCELATION_SWITCHPLAN_TRIGGERED })
        this.$emit('downgradeToFreeLight')
      } else {
        /* eslint-disable-next-line no-unused-expressions */
        this.$gtm?.trackEvent({ event: trackingEvents.CANCELATION_TRIGGERED })
        this.$emit('cancelContract')
      }
      this.close()
    },
    handleDowngrade () {
      this.$emit('handleDowngrade')
      this.close()
    },
    handleSwitchplan (plan) {
      this.selectedVariant = plan
    },
    close () {
      this.state = 'FEEDBACK'
      this.selectedVariant = this.isLight ? 'delete' : 'free'
      this.$emit('close')
    },
    async submitCancelationReason () {
      const reasons = this.cancelationReason.reasons
      if (!reasons || this.cancelationReason.other.length > 0) {
        reasons.push('OTHER')
      }
      await this.$apollo.mutate({
        mutation: UPDATE_CANCELATION,
        variables: {
          input: {
            companyId: this.$auth.user.companyId,
            other: this.cancelationReason.other,
            reasons
          }
        }
      })
      this.state = this.isFeatureActive(this.featureNames.CHANGE_SUBSCRIPTION) ? 'DOWNGRADE' : 'HELP'
    }
  }

}
</script>
